<template>
  <div id="app">
    <h1>Vue.js with Node.js Backend</h1>
    <h3>Message from backend server</h3>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    this.fetchFromServer();
  },
  methods: {
    fetchFromServer() {
      fetch("http://localhost:3000/api")
        .then((response) => response.text())
        .then((data) => {
          this.message = data;
        });
    },
  },
};
</script>

<style scoped>
#app {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #202324;
}

h1 {
  margin-bottom: 60px;
}

h3 {
  margin: 0;
}

p {
  padding: 20px;
  background-color: #ddd;
  border-radius: 12px;
}
</style>
